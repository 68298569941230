import { BareVariant, ExtendedProduct, Image, Product, ProductInfo, Variant } from 'types/types';
import { calcSavingPercentage } from 'lib/price';

export const getProductInfo = ({
  product,
  isPreview,
}: {
  product: Product;
  isPreview: boolean;
}): ProductInfo => {
  const isDisabled = isPreview || !product.sellable;

  return {
    isDisabled,
    sellable: !!product.sellable,
  };
};

export function getProductImages(product: Product, selectedVariant?: Variant | null) {
  let images: Image[];
  images = [];

  if (!!selectedVariant) {
    images = product.images.filter((img: Image) => img.variants.includes(selectedVariant.code));
  }

  return images.length === 0 ? product.images : images;
}

function describeDiscount(defaultVariant: BareVariant): string {
  const discount = calcSavingPercentage(defaultVariant.originalPrice, defaultVariant.price);

  if (!discount || discount === '0') {
    return '';
  }

  return `(-${discount}%) `;
}

function describePrice(defaultVariant: BareVariant): string {
  return (defaultVariant.price / 100).toFixed(2).replace('.', ',');
}

export function getSeoProductTitle(
  product: ExtendedProduct | null,
  defaultTitleSuffix: string
): string {
  if (!product) {
    return defaultTitleSuffix;
  }
  if (!product.metaTitle) {
    return product.name ? `${product.name} | ${defaultTitleSuffix}` : defaultTitleSuffix;
  }

  if (!product.defaultVariant) {
    return '%title% | %suffix%'
      .replace('%title%', product.metaTitle)
      .replace('%suffix%', defaultTitleSuffix);
  }

  return '%title% | %priceLabel% %price% %discount%| %suffix%'
    .replace('%title%', product.metaTitle)
    .replace('%priceLabel%', 'Actieprijs')
    .replace('%price%', describePrice(product.defaultVariant))
    .replace('%discount%', describeDiscount(product.defaultVariant))
    .replace('%suffix%', defaultTitleSuffix);
}
