import { MAX_PRODUCT_QUANTITY_PER_CUSTOMER } from 'constants/product';
import useReservationFlow from 'hooks/reservation/useReservationFlow';
import { buildCartReservation, computeMinimumQuantity } from 'lib/cart/cart';
import handleAddToCart, {
  canProductBeAddedToCart,
  getQuantityOfProductInCart,
} from 'lib/cart/handleAddToCart';
import handleEditCartItem from 'lib/cart/handleEditCartItem';
import { castToVariantOrNull } from 'lib/products/castToVariantOrNull';
import isReservationEnabled from 'lib/reservation/isReservationEnabled';
import validation from 'lib/validation';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getAddToCartContext,
  resetAddToCart,
  setAddToCartProduct,
  setAddToCartVariant,
  setPreviousProduct,
  setProductList,
  setQuantity,
  setShowSlider,
  setSubmitted,
  setSubmitting,
} from 'redux/cart/addToCart.slice';
import {
  getComments,
  getPhone,
  getSelectedDate,
  getSelectedTimeslot,
  resetReservation,
} from 'redux/cart/reservation.slice';
import { TrackingProductList } from 'types/tracking';
import { Product, Variant } from 'types/types';
import useStore from '../store';
import useSession from './useSession';
import useCartReminderViewCount from 'hooks/cartItem/useCartReminderViewCount';

const useCart = () => {
  const { t } = useTranslation();
  const { session } = useSession();
  const [cart, setCart] = useStore('cart');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const {
    product: _product,
    variant,
    quantity,
    submitting,
    productList,
    isEdit,
    editOrderItemId,
  } = useAppSelector(getAddToCartContext);
  const selectedDate = useAppSelector(getSelectedDate);
  const selectedTimeslot = useAppSelector(getSelectedTimeslot);
  const phone = useAppSelector(getPhone);
  const comments = useAppSelector(getComments);
  const { updateAvailabilityCalendar } = useReservationFlow();
  const { resetCounter } = useCartReminderViewCount();

  const validateReserveNow = (): string | null => {
    setErrorMessage(null);
    if (!selectedDate) return t('app.ui.reservation.error.date');
    if (!selectedTimeslot) return t('app.ui.reservation.error.timeslot');
    if (!validation.isValidPhone(phone)) return t('app.ui.reservation.error.phone_number');
    return null;
  };

  const submitAddToCartContext = async (isReserveLater?: boolean, bookingExternalId?: string) => {
    setErrorMessage(null);
    dispatch(setSubmitted(false));
    if (!_product || submitting) return;
    resetCounter();

    dispatch(setSubmitting(true));
    try {
      const reservation =
        !isReserveLater && selectedDate && selectedTimeslot
          ? buildCartReservation({
              date: selectedDate,
              time: selectedTimeslot,
              phone,
              comments,
            })
          : undefined;

      if (isEdit) {
        await handleEditCartItem({
          cart,
          authToken: session ? session.token : null,
          orderItemId: editOrderItemId as number,
          quantity,
          reservation,
          setCart,
          t,
        });
        // after successful edit, we want to close the slider and reset ATC states
        dispatch(setShowSlider(false));
        dispatch(resetReservation());
        dispatch(resetAddToCart());
      } else {
        await handleAddToCart({
          cart,
          product: _product,
          variant: variant ?? _product.variants[0],
          quantity,
          setCart,
          productList: productList || null,
          t,
          authToken: session ? session.token : null,
          reservation,
          bookingExternalId,
        });
        dispatch(setSubmitted(true));
      }
    } catch (errorMessage) {
      if (typeof errorMessage === 'string') {
        setErrorMessage(errorMessage);
      }
      // explicitly throw error
      throw errorMessage;
    } finally {
      dispatch(setSubmitting(false));
    }
  };

  const openSlideOver = async ({
    product,
    variant,
    productList,
    quantity,
    isRecommended = false,
  }: {
    product: Product;
    variant?: Variant;
    quantity?: number;
    productList?: TrackingProductList;
    isRecommended?: boolean;
  }) => {
    const previousProduct = isRecommended && _product ? _product : null;
    const _variant = variant ?? castToVariantOrNull(product.defaultVariant);

    dispatch(setSubmitted(false));
    dispatch(setSubmitting(false));
    dispatch(setPreviousProduct(previousProduct));
    dispatch(setShowSlider(true));
    dispatch(setAddToCartProduct(product));
    dispatch(setAddToCartVariant(_variant));
    dispatch(setQuantity(computeMinimumQuantity({ choosenQuantity: quantity, product })));
    dispatch(setProductList(productList || null));

    if (isReservationEnabled(_variant))
      await updateAvailabilityCalendar({
        variant: _variant,
        quantity,
        showFirstAvailability: true,
      });
  };

  const getMaxQuantityAllowed = useCallback((): number => {
    if (!_product) return 0;
    if (!_product?.customerBuyingLimit) return MAX_PRODUCT_QUANTITY_PER_CUSTOMER;
    if (isEdit) return _product.customerBuyingLimit;

    const quantityInCart = getQuantityOfProductInCart(_product, cart);
    if (quantityInCart >= _product.customerBuyingLimit) return 0;

    return _product.customerBuyingLimit - quantityInCart;
  }, [_product, cart, isEdit]);

  const canProductBeBought = useCallback((): boolean => {
    if (!_product) return false;
    return canProductBeAddedToCart(_product, cart);
  }, [cart, _product]);

  const canBeAddedAutomaticallyToTheCart = useCallback((): boolean => {
    if (!_product) return false;
    return (
      _product &&
      _product.variants.length === 1 &&
      getMaxQuantityAllowed() === 1 &&
      canProductBeBought()
    );
  }, [canProductBeBought, getMaxQuantityAllowed, _product]);

  return {
    submitAddToCartContext,
    errorMessage,
    setErrorMessage,
    validateReserveNow,
    openSlideOver,
    canProductBeBought,
    canBeAddedAutomaticallyToTheCart,
    getMaxQuantityAllowed,
  };
};

export default useCart;
