import { ImageSlider } from 'components/image/ImageSlider';
import { ProductBoxImage } from 'components/product/ProductBoxImage';
import { getProductImages } from 'lib/products/product';
import { productClick } from 'modules/tracking/events/events';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import ProductLabel from 'components/product/ProductLabel';
import { Z_INDEX } from 'constants/common';
import { memo, useState } from 'react';
import isEqual from 'lodash.isequal';
import ProductInfo from './ProductInfo';
import { ProductSavings } from './ProductSavings';
import { PRODUCT_CARD_DIMENSIONS } from 'constants/productCard/productCard';
import { ProductCardProps, ProductCardSizes } from 'types/productCard';

export const PLACEMENT_TYPES = {
  UNDER_IMAGE: 'underImage',
  ON_TOP_OF_IMAGE: 'onTopOfImage',
};

export type ProductBoxDetailPlacementTypes = (typeof PLACEMENT_TYPES)[keyof typeof PLACEMENT_TYPES];

type Props = {
  size: ProductCardSizes;
} & ProductCardProps;

const ProductBoxComponent = ({
  size,
  product,
  showAddToCartButton = false,
  productDetailPlacement = PLACEMENT_TYPES.UNDER_IMAGE,
  priority,
  className,
  imageSlider = false,
  productList,
  productPosition,
  isRecommended = false,
  imageSizes = '100vw',
  imageSliderClass,
  showLocationPin = true,
}: Props) => {
  const [currentImagePosition, setCurrentImagePosition] = useState(0);

  const onClick = async () => {
    await productClick({ product, productList, productPosition });
  };

  const productImages = getProductImages(product);
  const isAddToCartEnabled = product.sellable && !product.isAffiliateDeal;

  const containerClassName = `min-w[${PRODUCT_CARD_DIMENSIONS[size].minWidth}px] max-w-[${PRODUCT_CARD_DIMENSIONS[size].maxWidth}px]`;

  return (
    <article key={`product-link-${product.id}`} className={containerClassName}>
      <Link
        href={product.href}
        data-testid="productBoxRoot"
        className={twMerge('block w-full shadow-default bg-white h-full rounded-md', className)}
        onClick={onClick}
        prefetch={false}
      >
        <div className="relative group mb-2">
          {!product.hasExternalBooking && (
            <ProductSavings
              originalPrice={product.defaultVariant.originalPrice}
              price={product.defaultVariant.price}
            />
          )}
          <div className="relative">
            {product.tag && productDetailPlacement === PLACEMENT_TYPES.UNDER_IMAGE && (
              <div className={`absolute top-2 left-2 ${Z_INDEX.level20}`}>
                <ProductLabel tag={product.tag} />
              </div>
            )}
            {imageSlider ? (
              <ImageSlider
                current={currentImagePosition}
                setCurrent={setCurrentImagePosition}
                images={productImages}
                sizeClassNames={`w-full h-full`}
                className={imageSliderClass}
                isSwipable={false}
              />
            ) : (
              productImages.length > 0 && (
                <ProductBoxImage
                  images={productImages}
                  imageAlt={product.imageAlt}
                  imageSizes={imageSizes}
                  priority={priority}
                />
              )
            )}
          </div>

          {productDetailPlacement === PLACEMENT_TYPES.ON_TOP_OF_IMAGE && (
            <div className={`absolute w-full bottom-1`}>
              <div className={`mx-4 flex justify-start relative gap-2 ${Z_INDEX.level20}`}>
                {product.tag && (
                  <div className={`mb-2 flex`}>
                    <ProductLabel tag={product.tag} isBig={true} />
                  </div>
                )}
                <div
                  className={
                    'grow flex-shrink-0 basis-2/3 mb-2 py-2 bg-white px-4 bg-opacity-95 rounded-md'
                  }
                >
                  <ProductInfo
                    productDetailPlacement={productDetailPlacement}
                    product={product}
                    showAddToCartButton={isAddToCartEnabled && showAddToCartButton}
                    productPosition={productPosition}
                    productList={productList}
                    isRecommended={isRecommended}
                    showLocationPin={showLocationPin}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {productDetailPlacement === PLACEMENT_TYPES.UNDER_IMAGE && (
          <div className={'py-2 md:py-2 px-2 md:px-4'}>
            <ProductInfo
              productDetailPlacement={productDetailPlacement}
              product={product}
              showAddToCartButton={
                (isAddToCartEnabled && showAddToCartButton) || product.hasExternalBooking
              }
              productPosition={productPosition}
              productList={productList}
              isRecommended={isRecommended}
              showLocationPin={showLocationPin}
            />
          </div>
        )}
      </Link>
    </article>
  );
};

export const ProductBox = memo(ProductBoxComponent, (prevProps, nextProps) => {
  const prevShowLocationPinBool = prevProps.showLocationPin ?? true;
  const nextShowLocationPinBool = nextProps.showLocationPin ?? true;

  return (
    isEqual(prevProps.product, nextProps.product) &&
    prevProps.size === nextProps.size &&
    prevProps.showAddToCartButton === nextProps.showAddToCartButton &&
    prevProps.priority === nextProps.priority &&
    prevProps.className === nextProps.className &&
    prevProps.imageSlider === nextProps.imageSlider &&
    prevProps.productPosition === nextProps.productPosition &&
    prevProps.productList === nextProps.productList &&
    prevProps.isRecommended === nextProps.isRecommended &&
    prevProps.imageSizes === nextProps.imageSizes &&
    prevProps.imageSliderClass === nextProps.imageSliderClass &&
    prevShowLocationPinBool === nextShowLocationPinBool
  );
});
