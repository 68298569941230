import { FC } from 'react';
import { calcSavingPercentage } from 'lib/price';
import { Z_INDEX } from 'constants/common';
import classNames from 'classnames';

interface Props {
  originalPrice: number | null;
  price: number | null;
  size?: 's' | 'l';
}

export const ProductSavings: FC<Props> = ({ originalPrice, price = null, size = 's' }) => {
  const savings = calcSavingPercentage(originalPrice, price);

  if (savings === null) return null;

  const wrapperClass = classNames(`product-savings absolute ${Z_INDEX.level10} -bottom-2`, {
    'left-2': size === 's',
    'left-4': size === 'l',
  });

  const rotatedClass = classNames(
    'origin-center -rotate-[2deg] bg-[#FCE91F] text-center content-center',
    {
      'h-8 w-16': size === 's',
      'h-10 w-24': size === 'l',
    }
  );

  const textClass = classNames('text-black font-black origin-center rotate-[2deg]', {
    'text-[1.05rem]': size === 's',
    'text-[1.4rem]': size === 'l',
  });

  return (
    <div className={wrapperClass}>
      <div className={rotatedClass}>
        <p translate="no" className={textClass}>
          -{savings}%
        </p>
      </div>
    </div>
  );
};
