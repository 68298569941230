import { useTranslation } from 'react-i18next';
import { PresentationalPriceProps } from 'components/price/PriceWithUnitAbbreviation';
import OriginalPrice from 'components/price/OriginalPrice';
import CurrentPrice from 'components/price/CurrentPrice';

export default function PriceHorizontally({
  originalPrice = null,
  price,
  hasStar,
  showFromText = true,
  hasCurrencySign = true,
}: PresentationalPriceProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-end">
      {showFromText && (
        <div>
          <p className="whitespace-nowrap overflow-ellipsis pl-3 pr-1">{t('app.ui.price')}</p>
        </div>
      )}
      {originalPrice && originalPrice > 0 ? (
        <div className="flex-1">
          <div className="text-left whitespace-nowrap overflow-ellipsis pl-3 pr-2">
            <OriginalPrice price={originalPrice} hasCurrencySign={hasCurrencySign} />
            {hasStar && <span className={`text-xs text-gray-500 absolute top-[-5px]`}>*</span>}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="flex-1">
        <div className="text-right whitespace-nowrap overflow-ellipsis pr-3">
          <CurrentPrice price={price} hasCurrencySign={hasCurrencySign} />
        </div>
      </div>
    </div>
  );
}
