import { ProductCardSizes } from 'types/productCard';
import { PRODUCT_CARD_SIZES, SIZE_CUTOFF_POINTS } from 'constants/productCard/productCard';

export const getSizeLabel = (width: number): ProductCardSizes => {
  switch (true) {
    case width <= SIZE_CUTOFF_POINTS.XS_MAX:
      return PRODUCT_CARD_SIZES.XS;
    case width > SIZE_CUTOFF_POINTS.XS_MAX && width <= SIZE_CUTOFF_POINTS.S_MAX:
      return PRODUCT_CARD_SIZES.S;
    case width > SIZE_CUTOFF_POINTS.S_MAX && width <= SIZE_CUTOFF_POINTS.M_MAX:
      return PRODUCT_CARD_SIZES.M;
    case width > SIZE_CUTOFF_POINTS.M_MAX && width <= SIZE_CUTOFF_POINTS.L_MAX:
      return PRODUCT_CARD_SIZES.L;
    default:
      return PRODUCT_CARD_SIZES.XL;
  }
};
