import { getCents, getEuros } from 'lib/price';
import CurrencySign from 'components/price/CurrencySign';

export type PresentationalPriceProps = {
  price: number;
  hasCurrencySign?: boolean;
  showCents?: boolean;
};

export default function OriginalPrice({
  price,
  hasCurrencySign,
  showCents = true,
}: PresentationalPriceProps) {
  const comma = showCents ? ',' : '';

  return (
    <del translate="no" data-aq={'originalPrice'}>
      {hasCurrencySign && (
        <span className="text-sm text-gray-500">
          <CurrencySign />
        </span>
      )}
      <span className="text-sm text-gray-500">
        {getEuros(price)}
        {comma}
      </span>
      {showCents && <span className="text-xs text-gray-500 align-top">{getCents(price)}</span>}
    </del>
  );
}
